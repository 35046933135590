<script setup>
const props = defineProps({
  theme: Object,
  body: String,
});

const element = ref(null);
const { toCSS } = useTheme({ element, theme: props.theme });
const isArticle = inject("isArticle", false);
</script>
<template>
  <div
    class="text"
    :class="{
      'text--article': isArticle,
    }"
    ref="element"
    :style="{
      ...toCSS(theme),
    }"
  >
    <div class="text__inner">
      <span class="text__body ts-body-a" v-html="body"></span>
    </div>
  </div>
</template>

<style lang="postcss">
.text {
  background: hsl(var(--theme-bg));
  color: hsl(var(--theme-text));

  &__inner {
    padding: units(4) var(--grid-margin);
    display: flex;
    flex-direction: column;

    @media (--md) {
      padding: units(10) var(--grid-margin) units(4);
    }
  }

  &__body {
    @media (--md) {
      width: cols(8);
      margin-left: push(3);
    }

    ol {
      margin: units(3) 0 units(3) units(3.5);
    }

    ul {
      margin: units(3) 0 units(3) units(3);
      list-style: square;
    }

    ol,
    ul {
      li + li {
        margin-top: units(1);
      }
    }

    u {
      background: hsl(var(--theme-text));
      color: hsl(var(--theme-bg));
      text-decoration: 0;
      padding: 0 0.2em;
    }

    table {
      width: 100%;
      border: 1px solid;
      border-collapse: collapse;
      margin: units(7) 0;

      th {
        font-family: var(--simula);
        font-size: var(--ts-h6-size);
        line-height: 1.1;
      }

      td {
        font-size: var(--ts-body-1-size);
        line-height: 1.5;
        vertical-align: top;
      }

      th,
      td {
        border-bottom: 1px solid;
        text-align: left;
        padding: units(1.6);
        font-weight: normal;

        & + th,
        & + td {
          border-left: 1px solid;
        }
      }

      tr + tr {
        border-top: 1px solid;
      }
    }
  }

  &--article {
    .text__body {
      h1,
      h2,
      h3,
      h4 {
        padding-top: 2rem;
      }
    }
  }
}
</style>
